import _ from 'lodash';
import * as fb from '@/firebase';
import Schema from 'async-validator';
import moment from 'moment';
/* eslint-disable class-methods-use-this  */

export default class BaseModule {
  constructor(options) {
    const self = this;
    this.name = options.name;
    this.schema = options.schema;
    this.getCollectionPath = options.getCollectionPath;
    this.collection = undefined;

    this.converter = {
      toFirestore(data) {
        const doc = {};
        console.log('toFirestore');
        _.forEach(self.schema, (value, key) => {
          doc[key] = data[key];
        });
        doc.createdAt = data.createdAt;
        doc.updatedAt = data.updatedAt;
        console.log(doc);
        return doc;
      },
      fromFirestore(snapshot, op) {
        const data = snapshot.data(op);
        const doc = {};

        _.forEach(self.schema, (value, key) => {
          if (value.type === 'date' && data[key] !== undefined && data[key] !== null) {
            doc[key] = moment(data[key].toDate());
          } else {
            doc[key] = data[key];
          }
        });

        doc.id = snapshot.id;
        if (data.createdAt) {
          doc.createdAt = data.createdAt.toDate();
        }
        if (doc.updatedAt) {
          doc.updatedAt = data.updatedAt.toDate();
        }

        return doc;
      },
    };
  }

  addDefaultValues(data) {
    const doc = {};
    _.forEach(this.schema, (value, key) => {
      if (data[key] === undefined || data[key] === null) {
        if (value.default !== undefined || value.default !== null) {
          doc[key] = value.default;
        } else {
          doc[key] = null;
        }
      } else {
        doc[key] = data[key];
      }
    });

    if (data.createdAt) {
      doc.createdAt = data.createdAt;
    }
    if (!data.id) {
      doc.createdAt = fb.firebase.firestore.FieldValue.serverTimestamp();
      doc.updatedAt = fb.firebase.firestore.FieldValue.serverTimestamp();
    } else {
      doc.id = data.id;
      doc.updatedAt = fb.firebase.firestore.FieldValue.serverTimestamp();
    }
    return doc;
  }

  state() {
    return {
      items: [],
      current: null,
      loading: false,
    };
  }

  getters() {
    return {
      list: (state) => state.items,
      get: (state, id) => _.find(state.items, { id }),
      current: (state) => _.find(state.items, { id: state.current }),
      loading: (state) => state.loading,
      subscribe: (state) => state.subscribe,
      schema: () => this.schema,
    };
  }

  actions() {
    return {
      get: async (context, payload) => {
        const { id } = payload;

        if (typeof id === 'undefined' || id === null) {
          throw new Error('Id is required for get method');
        }

        context.commit('setLoading', true);
        this.collection = fb.db.collection(this.getCollectionPath(context));

        return this.collection
          .doc(id)
          .withConverter(this.converter)
          .get()
          .then(async (res) => {
            const doc = await res.data();
            context.commit('addItem', doc);
            context.commit('setLoading', false);
            return doc;
          })
          .catch((error) => {
            console.log(error);
            const e = Object.assign(error, {
              message: `Error getting ${this.name}`,
            });
            throw e;
          });
      },
      find: async (context, payload) => {
        const { query, subscribe, order } = payload;

        this.collection = fb.db.collection(this.getCollectionPath(context));
        context.commit('setLoading', true);
        const list = [];
        let ref = this.collection;

        /* Add Query */

        if (query) {
          if (!Array.isArray(query)) {
            throw new Error('Query should be an Array');
          }
          _.forEach(query, (value) => {
            if (!Array.isArray(value) || value.length !== 3) {
              throw new Error('Query should containe an Array [fieldName, condution, value]');
            }
            ref = ref.where(value[0], value[1], value[2]);
          });
        }

        /* Add Order */
        if (order) {
          if (order.desc) {
            ref = ref.orderBy(order.field, 'desc');
          } else {
            ref = ref.orderBy(order.field);
          }
        }

        if (subscribe) {
          return ref.withConverter(this.converter).onSnapshot((snapshot) => {
            context.commit('setLoading', false);
            snapshot.docChanges().forEach((change) => {
              if (change.type === 'added') {
                context.commit('addItem', change.doc.data());
              }
              if (change.type === 'modified') {
                context.commit('addItem', change.doc.data());
              }
              if (change.type === 'removed') {
                context.commit('deleteItem', change.doc.id);
              }
            });
          });
        }
        const start = moment();
        return ref
          .withConverter(this.converter)
          .get()
          .then((res) => {
            const end = moment();
            const duration = moment.duration(end.diff(start)).asMilliseconds();
            console.log(duration);
            res.forEach(async (doc) => {
              const data = await doc.data();
              context.commit('addItem', data);
              list.push(data);
            });
            context.commit('setLoading', false);
            return list;
          })
          .catch((error) => {
            console.log(error);
            const e = Object.assign(error, {
              message: `Error finding ${this.name} list`,
            });
            throw e;
          });
      },
      save: async (context, payload) => {
        const { data } = payload;
        if (typeof data.id === 'undefined' || data.id === null) {
          return context.dispatch('create', payload).then((doc) => doc);
        }
        return context.dispatch('update', payload).then((doc) => doc);
      },
      update: async (context, payload) => {
        console.log('updating');
        let { data } = payload;
        if (typeof data.id === 'undefined' || data.id === null) {
          throw new Error('Id is required for update method');
        }
        data = this.addDefaultValues(data);

        const validator = new Schema(this.schema);
        await validator.validate(data).catch((error) => {
          console.log(error);
          const e = Object.assign(error, {
            message: `${this.name} validation error`,
          });
          throw e;
        });

        this.collection = fb.db.collection(this.getCollectionPath(context));
        context.commit('setLoading', true);

        let doc = null;
        return this.collection
          .doc(data.id)
          .withConverter(this.converter)
          .set(data)
          .then(async () => {
            await this.collection
              .doc(data.id)
              .withConverter(this.converter)
              .get()
              .then(async (d) => {
                doc = await d.data();
              });

            context.commit('addItem', doc);
            context.commit('setLoading', false);
            console.log('updated');
            return doc;
          })
          .catch((error) => {
            console.log(error);
            const e = Object.assign(error, {
              message: `Error updating ${this.name}`,
            });
            throw e;
          });
      },
      patch: (context, payload) => {
        const { id, data } = payload;
        context.commit('setLoading', true);
        if (typeof id === 'undefined' || id === null) {
          throw new Error('Id is required for patch method');
        }
        if (typeof data === 'undefined' || data === null) {
          throw new Error('Data is required for patch method');
        }
        this.collection = fb.db.collection(this.getCollectionPath(context));

        return this.collection
          .doc(id)
          .withConverter(this.converter)
          .update(data)
          .then(() => this.collection
            .doc(id)
            .withConverter(this.converter)
            .get()
            .then((doc) => {
              context.commit('addItem', doc.data());
              context.commit('setLoading', false);
              return doc.data();
            }));
      },
      create: async (context, payload) => {
        let { data } = payload;

        context.commit('setLoading', true);
        data = this.addDefaultValues(data);

        const validator = new Schema(this.schema);

        await validator.validate(data).catch((error) => {
          console.log(error);
          const e = Object.assign(error, {
            message: `${this.name} validation error`,
          });
          throw e;
        });

        this.collection = fb.db.collection(this.getCollectionPath(context));
        return this.collection
          .withConverter(this.converter)
          .add(data)
          .then(async (ref) => {
            let doc = null;
            await ref
              .withConverter(this.converter)
              .get()
              .then(async (d) => {
                doc = await d.data();
              });
            context.commit('addItem', doc);
            context.commit('setLoading', false);
            console.log('created');
            return doc;
          })
          .catch((error) => {
            console.log(error);
            const e = Object.assign(error, {
              message: `Error creating ${this.name}`,
            });
            throw e;
          });
      },
      delete: (context, payload) => {
        const { id } = payload;
        this.collection = fb.db.collection(this.getCollectionPath(context));
        // context.commit('setLoading', true);
        return this.collection
          .doc(id)
          .delete()
          .then(() => {
            context.commit('deleteItem', id);
            context.commit('setLoading', false);
          })
          .catch((err) => {
            console.log(err);
            context.commit('setLoading', false);
          });
      },
      setCurrent: (context, payload) => {
        const { id } = payload;

        if (typeof id === 'undefined' || id === null) {
          throw new Error('Id is required for setCurrent method');
        }
        context.commit('setCurrent', id);
      },
      clearItems: (context) => context.commit('clearItems'),
      unSubscribe: (context) => {
        if (context.state.subscribe) {
          context.state.subscribe();
        }
      },
    };
  }

  mutations() {
    return {
      setCurrent: (state, payload) => {
        if (typeof payload === 'object' && payload !== null) {
          state.current = payload.id;
        } else {
          state.current = payload;
        }
      },
      setItems: (state, payload) => {
        state.items = payload;
      },
      addItem: (state, doc) => {
        const item = _.find(state.items, { id: doc.id });

        if (item) {
          console.log(item);
          Object.assign(item, doc);
        } else {
          state.items.push(doc);
        }
      },
      deleteItem: (state, id) => {
        const index = _.findIndex(state.items, { id });
        state.items.splice(index, 1);
      },
      setLoading: (state, payload) => {
        state.loading = payload;
      },
      clearItems: (state) => {
        state.items = [];
      },
    };
  }

  modules() {
    return {};
  }

  getModule = () => ({
    namespaced: true,
    state: this.state(),
    getters: this.getters(),
    actions: this.actions(),
    mutations: this.mutations(),
    modules: this.modules(),
  });
}
/* eslint-enable class-methods-use-this */
