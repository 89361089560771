<template>
  <div class="embed-responsive embed-responsive-16by9">
    <div
      id="player"
      class="player embed-responsive-item"
      style="border-radius: 8px; overflow: hidden"
    >
      <iframe
        :src="stream.vodUrl"
        width="640"
        height="360"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      player: null,
      isReady: false,
    };
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
    }),
  },
};
</script>

<style>
</style>
