/* eslint-disable no-underscore-dangle */
import BaseModule from '@/store/baseFireVuex';
/*
import axios from 'axios';
*/

/* eslint-disable max-classes-per-file */

const schema = {
  text: { type: 'string', required: true },
  bucket: { type: 'string', required: true },
  userName: { type: 'string', required: true },
  location: { type: 'string', required: false },
  isApproved: { type: 'boolean', required: true, default: false },
  isAnswered: { type: 'boolean', required: true, default: false },
};

/* eslint-disable no-unused-vars */
const options = {
  name: 'Question',
  getCollectionPath: (context) => {
    const { rootState } = context;
    return `/event/${rootState.event.current._id}/question`;
  },
  schema,
};

/* eslint-disable no-unused-vars */
class QuestionModule extends BaseModule {}

export default new QuestionModule(options).getModule();
/* eslint-enable max-classes-per-file */
