const getters = {
  current: (state) => state.current,
};
const actions = {
  setCurrent: (context, payload) => {
    context.commit('setCurrent', payload);
  },
};
const mutations = {
  setCurrent: (state, payload) => {
    state.current = payload;
  },
};

const state = {
  current: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
