<template>
  <div class="embed-responsive embed-responsive-16by9">
    <div
      id="player"
      class="player embed-responsive-item"
      style="border-radius: 8px; overflow: hidden"
    ></div>
  </div>
</template>

<script>
import Clappr from 'clappr';
import ClapprStats from 'clappr-stats';
import LevelSelector from 'level-selector';
import trackPlayer from '@/utils/playerReports';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      player: null,
      isReady: false,
    };
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
    }),

    playerUrl() {
      if (_.has(this.stream, 'playerUrl')) {
        if (this.stream.playerUrl !== '') {
          return this.stream.playerUrl;
        }
      }
      return `${'//cdn23.livecasthq.com/platform/ngrp:'}${this.stream.streamKey}_all/playlist.m3u8`;
    },
    statsData() {
      return {
        event: this.event,
        stream: this.stream,
        user: this.user,
      };
    },
  },
  watch: {
    stream(n, o) {
      if (n !== o) {
        if (this.player) {
          this.player.destroy();
          this.player = null;
        }
        this.createPlayer();
      }
    },
  },
  methods: {
    createPlayer() {
      const self = this;
      this.player = new Clappr.Player({
        source: this.playerUrl,
        parentId: '#player',
        Preload: 'metadata',
        width: '100%',
        height: '100%',
        allowUserInteraction: false,
        actualLiveTime: true,
        autoPlay: true,
        maxBufferLength: 1,
        plugins: [LevelSelector, ClapprStats],
        levelSelectorConfig: {
          title: 'Quality',
          labelCallback(playbackLevel) {
            return `${playbackLevel.level.height}p`; // High 720p
          },
        },
        playback: {
          playInline: true,
        },
        clapprStats: {
          runEach: 20000,
          onReport: (metrics) => {
            /* eslint-disable no-param-reassign */
            metrics.isPlaying = self.player.isPlaying();
            trackPlayer.metrics(metrics, self.statsData);
          },
        },
        events: {
          onReady() {
            trackPlayer.ready(self.statsData);
            const container = this.core.activeContainer;
            container.seek(container.getDuration());
          },
          onPlay() {
            trackPlayer.play(self.statsData);
          },
          onPause() {
            trackPlayer.pause(self.statsData);
          },
          onStop() {
            trackPlayer.stop(self.statsData);
          },
        },
      });
    },
  },
  async mounted() {
    this.createPlayer();
    this.isReady = true;
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
      this.player = null;
    }
  },
};
</script>

<style>
</style>
