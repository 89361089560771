/* eslint-disable no-underscore-dangle */
import _ from 'lodash';

const getters = {
  current: (state) => _.find(state.list, { _id: state.current }),
  list: (state) => state.list,
};
const actions = {
  setCurrent: (context, payload) => {
    const { _id } = payload;

    if (typeof _id === 'undefined' || _id === null) {
      throw new Error('Id is required for setCurrent method');
    }
    context.commit('setCurrent', _id);
  },

  setList: (context, payload) => {
    context.commit('setList', payload);
  },
};
const mutations = {
  setCurrent: (state, payload) => {
    state.current = payload;
  },
  setList: (state, payload) => {
    state.list = payload;
  },
};

const state = {
  current: null,
  list: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
