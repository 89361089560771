<template>
  <div class="embed-responsive embed-responsive-16by9 waiting-screen">
    <div
      id="player"
      class="embed-responsive-item"
      style="border-radius: 8px; overflow: hidden"
    >
      <div class="text-wrapper">
        <h5>{{ $t("this_event_completed") }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      event: 'event/current',
    }),
  },
};
</script>

<style lang="scss">
.waiting-screen {
  background-color: #201f1f;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  .embed-responsive-item {
    display: flex;
    justify-content: center;
    align-items: center;
    .text-wrapper {
      text-align: center;
      font-size: 0.875rem;
    }
  }
}
</style>
