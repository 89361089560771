import { rtdb, firebase } from '@/firebase';
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
let userSessionId = null;
let statRef = null;
const createUserSession = async (statData, createNew = false) => {
  if (createNew === true && userSessionId) {
    const data = {
      status: 'stopped',
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    };
    await statRef.update(data);
  }

  if (!userSessionId || createNew === true) {
    const data = {
      fbToken: null,
      token: null,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
    };
    // ${statData.stream._id}
    const refStr = `event/${statData.event._id}/${statData.event.currentSession}`;
    console.log(refStr);
    statRef = rtdb.ref(refStr);
    userSessionId = statRef.push().key;
    statRef = statRef.child(userSessionId);
    await statRef.set({ ...statData.user, ...data, streamId: statData.stream._id });

    rtdb.ref('.info/connected').on('value', (snapshot) => {
      if (snapshot.val() === false) {
        return;
      }

      statRef.onDisconnect().update({ status: 'disconnected' });
    });
  }
};

export default {
  metrics: async (data, statData) => {
    await createUserSession(statData);
    data.extra = null;
    let status = 'stopped';
    if (data.isPlaying) {
      status = 'playing';
    }
    const metricsData = {
      metrics: data,
      status,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    };
    await statRef.update(metricsData);
  },
  ready: async (statData) => {
    await createUserSession(statData, true);
    const data = {
      status: 'ready',
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    };

    await statRef.update(data);
  },
  play: async (statData) => {
    await createUserSession(statData);
    const data = {
      status: 'playing',
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    };
    await statRef.update(data);
    console.log('played');
  },
  pause: async (statData) => {
    await createUserSession(statData);
    const data = {
      status: 'paused',
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    };
    await statRef.update(data);
    console.log('paused');
  },
  stop: async (statData) => {
    await createUserSession(statData);
    const data = {
      status: 'stopped',
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    };
    await statRef.update(data);
    console.log('stopped');
  },
};
